import entities from 'config';
import ListPage, { TLinkEntity } from 'components/ListPage';
import { AdditionalConfigType, ApiFilter } from 'lib';
import { useCallback, useMemo } from 'react';
import { useAttendance } from 'schemas/session/components';
import { Action } from 'components/Actions';
import { config as sessionConfig } from 'schemas/session';
import { config as seminarConfig } from 'schemas/seminar';

export const links = {
  session: entities.session.columns,
  seminar: entities.seminar.columns,
} as TLinkEntity;

const columns = [
  ...entities.participant.columns,
  ...entities.session.columns.map((column) => `session.${column}`),
  ...entities.seminar.columns.map((column) => `seminar.${column}`),
] as const;

type Keys = (typeof columns)[number];

const config: AdditionalConfigType<Keys> = {
  bahai_sessionid: {
    searchable: true,
  },
  ...Object.fromEntries(
    Object.entries(sessionConfig).map(([key, props]) => ['session.' + key, { ...props, searchable: false }])
  ),
  ...Object.fromEntries(
    Object.entries(seminarConfig).map(([key, props]) => ['seminar.' + key, { ...props, searchable: false }])
  ),
};

export const SessionsTab = ({ bahai_personid }: { bahai_personid: string }) => {
  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_personid', value: bahai_personid }] }],
    [bahai_personid]
  );

  const { actions: attendanceActions } = useAttendance();

  const getActions = useCallback(
    (baseActions: Action[]) => [...baseActions, ...attendanceActions],
    [attendanceActions]
  );

  return (
    <>
      <ListPage
        systemView="Default for Person"
        entityName="participant"
        isCreateHidden
        isSubgrid={true}
        hiddenFilters={hiddenFilters}
        displayViews={false}
        allowRowClick={false}
        {...{ getActions, links, columns, config }}
      />
    </>
  );
};
