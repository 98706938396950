import { FC, ReactNode } from 'react';
import classes from './style.module.scss';
import { ReactComponent as Alert } from './alert.svg';

export const ListExclamation: FC<{ children: ReactNode; displayExclamation?: boolean }> = ({
  children,
  displayExclamation = true,
}) =>
  displayExclamation ? (
    <div className={classes.wrapper}>
      <Alert />
      <span className={classes.text}>{children}</span>
    </div>
  ) : (
    children
  );
