import entities, { SystemFields } from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { AreaTooltipComponent, EmailComponent, PhoneComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';
import { bahai_yearInUniversityStudy } from 'config/EntityMetadata/application';
import { TLinkEntity } from 'components/ListPage';
import { createFormAutocomplete } from 'components/Form/Autocomplete';
import { addressFieldsConfig } from 'lib/addressValidation';

export type Keys =
  | (typeof entities.application.columns)[number]
  | 'bahai_applicationid'
  | SystemFields
  | 'state.bahai_stateregionid'
  | 'systemuserid';

export const PersonStatusIsArchive = <Trans>Person is Archived and the State is Inactive</Trans>;

export const isNotEditable = (data: Record<string, any>) =>
  data._bahai_personid_value && !data['person.bahai_state'] ? PersonStatusIsArchive : false;

export const defaultMobileColumns: Keys[] = ['bahai_name', 'bahai_email', 'bahai_id'];

export const columns = [...entities.application.columns, 'state.bahai_stateregionid'];

export const personColumns = [
  'bahai_dateofbirth',
  'bahai_email',
  'bahai_firstname',
  'bahai_gendercode',
  'bahai_lastname',
  'bahai_phone',
  'bahai_note',
  'bahai_addressline',
  'bahai_city',
  'bahai_stateid',
  'bahai_zipcode',
  'bahai_phoneinput',
  'bahai_age',
];

export const links = {
  state: entities.state.columns,
  person: ['bahai_state'],
} as TLinkEntity;

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.application({ id: data.bahai_applicationid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_name: { searchable: true },
  bahai_firstname: { isRequired: true },
  bahai_lastname: { isRequired: true },
  bahai_email: { component: EmailComponent, isRequired: true, searchable: true },
  bahai_phone: {
    component: (props) => (
      <PhoneComponent {...props} name={props.data.bahai_phone ? 'bahai_phone' : 'bahai_phoneinput'} />
    ),
    isRequired: (values) => !values.bahai_phoneinput,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  bahai_phoneinput: {
    component: PhoneComponent,
    isDisabled: true,
  },
  bahai_dateofbirth: {
    fieldProps: () => ({ canSelectFutureDate: false }),
    isRequired: true,
  },
  bahai_otheryearinuniversitystudy: {
    isRequired: true,
    fieldProps: () => ({
      maxLength: 100,
    }),
  },
  bahai_intentionforcollegestudiescode: {
    fieldProps: ({ classes }) => ({ className: classes.long }),
  },
  bahai_intendedplansupongraduation: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 200,
    }),
  },
  bahai_universityname: {
    fieldProps: () => ({
      maxLength: 100,
    }),
  },
  bahai_majorfieldofstudy: {
    isRequired: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      maxLength: 100,
    }),
  },

  bahai_sourceoftheseminar: {
    component: AreaTooltipComponent,
    isRequired: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_interestinseminar: {
    isRequired: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_seminarexpectations: {
    isRequired: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_involvementinbahaicommunityactivities: {
    isRequired: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_completedruhiinstitutebooks: {
    isRequired: true,
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_challengeattendfullseminar: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_personalmedicalconditions: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_canattendfullseminar: {
    isRequired: true,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      showBooleanAsPicklist: true,
    }),
  },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_applicationstatuscode: { isRequired: true },
  bahai_applicationtypecode: { isRequired: true },
  bahai_yearinuniversitystudycode: { isRequired: true },
  bahai_personid: {
    fieldProps: ({ initialValues }) => ({ readOnly: !!initialValues?.bahai_personid }),
    field: createFormAutocomplete({
      entities: [
        {
          entityName: 'person',
          filters: {
            bahai_state: {
              operator: 'ne',
              value: 'false',
            },
          },
        },
      ],
    }),
  },
  ...(addressFieldsConfig as AdditionalConfigType<Keys>),
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_email: rules.compose([rules.required, rules.email, rules.maxLength(100)]),
  bahai_note: rules.maxLength(2000),
  bahai_phone: rules.compose([rules.conditionalRequired((values) => !values.bahai_phoneinput), rules.phone]),
  bahai_dateofbirth: rules.compose([rules.required, rules.pastDate]),
  bahai_firstname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_lastname: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_city: rules.compose([rules.required, rules.maxLength(30)]),
  bahai_stateid: rules.required,
  bahai_addressline: rules.compose([rules.required, rules.maxLength(500)]),
  bahai_zipcode: rules.compose([rules.required, rules.maxLength(10)]),
  bahai_applicationtypecode: rules.required,
  bahai_applicationstatuscode: rules.required,
  bahai_yearinuniversitystudycode: rules.compose([rules.required, rules.maxLength(100)]),
  bahai_otheryearinuniversitystudy: rules.conditionalRequired(
    (values) => Number(values.bahai_yearinuniversitystudycode) === bahai_yearInUniversityStudy.Other
  ),
  bahai_universityname: rules.maxLength(100),
  bahai_majorfieldofstudy: rules.compose([rules.required, rules.maxLength(100)]),
  bahai_sourceoftheseminar: rules.required,
  bahai_interestinseminar: rules.required,
  bahai_seminarexpectations: rules.required,
  bahai_involvementinbahaicommunityactivities: rules.required,
  bahai_completedruhiinstitutebooks: rules.required,
  bahai_canattendfullseminar: rules.required,
};

export const getContactFields = (data: Record<Keys, any>) => [
  'bahai_email',
  data.bahai_phone ? 'bahai_phone' : 'bahai_phoneinput',
];

export const getCommonFields = (): Keys[] => ['bahai_unitid', 'bahai_isgpregionid', 'ownerid', 'createdon'];
export const getTitleFields = (): Keys[] => ['bahai_firstname', 'bahai_lastname'];
export const getStatusFields = (): Keys[] => ['bahai_applicationtypecode', 'bahai_applicationstatuscode'];

export const getInitialValues = async () => ({ bahai_canattendfullseminar: false });

export const getDetailsConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_personid',
      'bahai_firstname',
      'bahai_lastname',
      'bahai_dateofbirth',
      'bahai_age',
      'bahai_gendercode',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      ...(!data.bahai_addressvalidationcode ? [] : (['bahai_addressvalidationmessage'] as Keys[])),
      'state.bahai_stateregionid',
      'bahai_note',
    ],
  ],
  [
    <Trans>Education</Trans>,
    [
      'bahai_intentionforcollegestudiescode',
      'bahai_intendedplansupongraduation',
      'bahai_yearinuniversitystudycode',
      ...((Number(data.bahai_yearinuniversitystudycode) === bahai_yearInUniversityStudy.Other
        ? ['bahai_otheryearinuniversitystudy']
        : []) as Keys[]),
      'bahai_majorfieldofstudy',
      'bahai_universityname',
      'bahai_universitystateid',
    ],
  ],
  [
    <Trans>Seminar Questions</Trans>,
    [
      'bahai_sourceoftheseminar',
      'bahai_interestinseminar',
      'bahai_seminarexpectations',
      'bahai_involvementinbahaicommunityactivities',
      'bahai_completedruhiinstitutebooks',
      'bahai_canattendfullseminar',
      'bahai_challengeattendfullseminar',
      'bahai_personalmedicalconditions',
    ],
  ],
];

export const getFormConfig: FormConfigGetter<Keys> = (data) => [
  [
    <Trans>Basic Information</Trans>,
    [
      'bahai_personid',
      'bahai_applicationtypecode',
      'bahai_email',
      ...((data['bahai_phoneinput'] ? ['bahai_phone', 'bahai_phoneinput'] : ['bahai_phone']) as Keys[]),
    ],
  ],
  [
    <Trans>Personal Information</Trans>,
    [
      'bahai_firstname',
      'bahai_lastname',
      'bahai_dateofbirth',
      'bahai_gendercode',
      'bahai_addressline',
      'bahai_city',
      'bahai_stateid',
      'bahai_zipcode',
      'bahai_note',
    ],
  ],
  [
    <Trans>Education</Trans>,
    [
      'bahai_intentionforcollegestudiescode',
      'bahai_intendedplansupongraduation',
      'bahai_yearinuniversitystudycode',
      ...((Number(data.bahai_yearinuniversitystudycode) === bahai_yearInUniversityStudy.Other
        ? ['bahai_otheryearinuniversitystudy']
        : []) as Keys[]),
      'bahai_majorfieldofstudy',
      'bahai_universityname',
      'bahai_universitystateid',
    ],
  ],
  [
    <Trans>Seminar Questions</Trans>,
    [
      'bahai_sourceoftheseminar',
      'bahai_interestinseminar',
      'bahai_seminarexpectations',
      'bahai_involvementinbahaicommunityactivities',
      'bahai_completedruhiinstitutebooks',
      'bahai_canattendfullseminar',
      'bahai_challengeattendfullseminar',
      'bahai_personalmedicalconditions',
    ],
  ],
];

export { getWarnings, preSubmit } from 'lib/addressValidation';
export { WarningsImprover } from 'schemas/person';
