import { ReactNode, useCallback, useMemo, useRef, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecord } from 'lib/record';
import { useNotifications, NotificationType } from 'providers/NotificationsProvider';
import { devLog } from 'lib/helpers';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { useMetaData, removePageFromQuery } from 'lib/hooks';
import classes from './invite.module.scss';
import ListPage, { TListPage } from 'components/ListPage';
import * as personConfig from 'schemas/person';
import { ReactComponent as AddIcon } from 'components/Actions/icons/add.svg';
import { Dialog } from 'components/Dialog';
import { ReactComponent as CheckedIcon } from 'components/Table/CheckBox/icons/checked.svg';
import { ReactComponent as UncheckedIcon } from 'components/Table/CheckBox/icons/unchecked.svg';
import { Button } from 'components/Button';
import { ScreenContext } from 'providers/ScreenProvider';
import { NotificationPopup } from 'components/NotificationPopup';
import { useListRecords } from 'domain/operations';

export const useInviteParticipant = (initialSessionId?: string, message?: string) => {
  const { t } = useTranslation();
  const { addNotification, addWarning, addActionUncompleted } = useNotifications();
  const [closeOnFinish, setCloseOnFinish] = useState(false);
  const toggleCloseOnFinish = useCallback(() => setCloseOnFinish((v) => !v), []);
  const [sessionId, setSessionId] = useState(initialSessionId);

  const { displayCollectionName, PrimaryIdAttribute, PrimaryNameAttribute, url } = useMetaData('person');

  const { isMobile } = useContext(ScreenContext);

  const getActions = useCallback(
    (baseActions: Array<Action>): Array<Action> =>
      baseActions.filter((v) => ['refresh', 'selectAll', 'unselectAll'].includes(v.name as string)),
    []
  );

  const [isVisible, setIsVisible] = useState(false);

  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => {
    if (invited.current > 0) reloadRef.current();
    invited.current = 0;
    setIsVisible(false);
  }, []);

  const invited = useRef(0);

  const [loadingText, setLoadingText] = useState('');

  const { save } = useRecord('participant');
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute, name: PrimaryNameAttribute });

  const inviteSelected = useCallback(
    async (selected: Record<string, any>[]) => {
      let index = 0;
      let success = 0;
      const errors: { label: string; content: ReactNode }[] = [];
      while (index < selected.length) {
        try {
          setLoadingText(`Inviting ${index + 1} / ${selected.length}`);
          await save({
            bahai_personid: selected[index].bahai_personid,
            bahai_sessionid: sessionId,
            bahai_attendancestatus: null,
          });
          success++;
        } catch (error) {
          let content = <Trans>Unknown Error</Trans>;
          try {
            content = JSON.parse((error as Record<string, any>).response.data.error.message)[0].Message;
          } catch (e) {
            devLog(e);
          }
          errors.push({ label: selected[index].bahai_name, content });
        }
        index++;
      }
      setLoadingText('');
      return { errors, success };
    },
    [sessionId, save]
  );

  const invite = useCallback(
    ({ selectedItems, query, reload }: { selectedItems: Record<string, any>[]; query: any; reload: () => void }) => {
      setLoadingText('Processing');
      const getItems = async () =>
        selectedItems.length
          ? selectedItems
          : (await getRecords(removePageFromQuery(query))).map(({ id, name }) => ({
              bahai_personid: id,
              bahai_name: name,
            }));
      getItems()
        .then(inviteSelected)
        .then(({ errors, success }) => {
          if (success > 0) {
            invited.current++;
          }
          if (errors.length === 0) {
            addNotification({
              type: NotificationType.SUCCESS,
              title: t('{{ displayCollectionName }} were added to the Session', { displayCollectionName }),
              content: t('Please continue adding persons to the Session'),
            });
          } else {
            addWarning({
              title:
                success === 0
                  ? t('{{ displayCollectionName }} were not added to the Session', {
                      displayCollectionName,
                    })
                  : t('Some {{displayCollectionName}} were not added to the Session', {
                      displayCollectionName,
                    }),
              content: (
                <>
                  {t('Please see details below.')}
                  <br />
                  <NotificationPopup
                    label={t('Show Details')}
                    header={t('Info')}
                    description={t('The {{ displayCollectionName }} listed below were not added to the Session', {
                      displayCollectionName,
                    })}
                    errors={errors}
                  />
                </>
              ),
            });
          }
          closeOnFinish && hide();
          reload();
        });
    },
    [addNotification, addWarning, closeOnFinish, displayCollectionName, getRecords, hide, inviteSelected, t]
  );

  const footer = useMemo(
    () =>
      ({
        selectedItems,
        query,
        reload,
        data,
        loading,
      }: {
        selectedItems: Record<string, any>[];
        query: any;
        data: Array<any>;
        reload: () => void;
        loading: boolean;
      }) => (
        <div className={classes.footer}>
          <div className={classes.keepWrapper}>
            <button onClick={toggleCloseOnFinish} className={classes.checkBoxWrapper} type="button">
              {closeOnFinish ? <UncheckedIcon /> : <CheckedIcon />}
            </button>
            <div className={classes.subHeader}>{t('Keep window open to continue adding participants')}</div>
          </div>
          <div className={classes.controls}>
            <Button
              disabled={data.length === 0 || loading}
              type="button"
              role="primary"
              onClick={() => invite({ selectedItems, query, reload })}
            >
              {selectedItems.length > 0 ? t('Add Selected') : t('Add All')}
            </Button>
            <Button type="button" role="flat" onClick={hide}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      ),
    [closeOnFinish, hide, invite, t, toggleCloseOnFinish]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const reloadRef = useRef(() => {});

  const action: Action = useMemo(
    () => ({
      title: displayCollectionName,
      name: 'invite',
      onClick: ({ selectedItems, reload }) => {
        if (message) {
          addActionUncompleted(message);
        } else {
          if (initialSessionId) {
            reloadRef.current = reload;
          } else {
            setSessionId(selectedItems[0].bahai_sessionid);
          }
          show();
        }
      },
      order: 23,
      Icon: AddIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItems }) => selectedItems.length === (initialSessionId ? 0 : 1),
      alwaysKeepTitle: false,
    }),
    [addActionUncompleted, displayCollectionName, message, show, initialSessionId]
  );

  const tableProps: TListPage & { columns: readonly string[] } = useMemo(() => {
    return {
      hiddenFilters: [
        {
          condition: [
            {
              operator: 'null',
              entityname: 'bahai_participant',
              attribute: 'bahai_sessionid',
            },
          ],
        },
        {
          condition: [
            {
              operator: 'not-null',
              attribute: 'bahai_personid',
            },
          ],
        },
      ],
      entityName: 'person',
      getActions,
      isSubgrid: true,
      isCreateHidden: true,
      displayViews: false,
      ...personConfig,
      links: {
        participant: {
          from: PrimaryIdAttribute,
          to: PrimaryIdAttribute,
          fields: [],
          condition: [{ operator: 'eq', attribute: 'bahai_sessionid', value: sessionId }],
        },
        ...personConfig.links,
      },
    };
  }, [PrimaryIdAttribute, sessionId, getActions]);

  const content = useMemo(
    () =>
      isVisible ? (
        <Dialog
          showZoom
          loading={!!loadingText}
          loadingText={loadingText}
          className={classes.dialog}
          fullScreen={isMobile}
          onClose={hide}
          centered
          styles={{ zIndex: 4 }}
          collapsedLabel={t('Invite {{ displayCollectionName }}', {
            displayCollectionName,
          })}
        >
          <div className={classes.content}>
            <h2 className={classes.header}>{t('Invite {{displayCollectionName}}', { displayCollectionName })}</h2>
            <h3 className={classes.subHeader}>
              {t('{{ displayCollectionName }} already added to the Session are not shown in the list.', {
                displayCollectionName,
              })}
            </h3>
            <ListPage hideFullScreen dialog="person" systemView={'Default'} {...tableProps}>
              {footer}
            </ListPage>
          </div>
        </Dialog>
      ) : null,
    [displayCollectionName, footer, hide, isMobile, isVisible, loadingText, t, tableProps]
  );

  return { action, content };
};
