import { useLoader } from 'providers/LoaderProvider';
import { TEntityName } from 'lib';
import { Modal } from 'components/Modal';
import { Field, Form } from 'react-final-form';
import { Input } from 'components/Form';
import * as rules from 'lib/rules';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useRecord } from 'lib/record';
import { useNotifications } from 'providers/NotificationsProvider';
import { devLog } from 'lib/helpers';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { createPortal } from 'react-dom';
import { ReactComponent as ArchiveIcon } from './icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from './icons/unarchive.svg';
import { useMetaData } from 'lib/hooks';
import classes from './style.module.scss';

const validation = rules.compose([rules.required, rules.maxLength(500)]);

export const useArchiveAction = ({
  id,
  entityName,
  noteField,
  extraContent,
}: {
  id: string;
  entityName: TEntityName;
  noteField?: string;
  extraContent?: ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord(entityName);
  const [isOpened, setIsOpened] = useState(false);
  const close = useCallback(() => setIsOpened(false), []);
  const { showLoader, hideLoader } = useLoader();
  const { addActionCompleted } = useNotifications();
  const { getLabel } = useMetaData(entityName);

  const label = useMemo(() => (noteField ? getLabel(noteField) : ''), [getLabel, noteField]);

  const archive = useCallback(
    (data: Record<string, any>) => {
      setLoading(true);
      patch({ bahai_state: false, ...data }, id)
        .then(() => {
          setLoading(false);
          addActionCompleted();
          reloadRef.current && reloadRef.current();
          close();
        })
        .finally(() => setLoading(false));
    },
    [patch, id, addActionCompleted, close]
  );

  const unArchive = useCallback(
    (reload: () => void) => {
      showLoader(t('Loading...'));
      patch({ bahai_state: true }, id)
        .then(() => {
          hideLoader();
          addActionCompleted();
          reload();
        })
        .catch((e) => devLog(e))
        .finally(hideLoader);
    },
    [addActionCompleted, hideLoader, id, patch, showLoader, t]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        name: 'archive',
        title: t('Archive'),
        display: ({ data: { bahai_state = true } }) => bahai_state,
        onClick: ({ reload }) => {
          reloadRef.current = reload;
          if (noteField) {
            setIsOpened(true);
          } else {
            archive({});
          }
        },
        Icon: ArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
      {
        name: 'unArchive',
        title: t('Unarchive'),
        display: ({ data: { bahai_state = true } }) => !bahai_state,
        onClick: ({ reload }) => unArchive(reload),
        Icon: UnArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
    ],
    [archive, noteField, t, unArchive]
  );

  const content = createPortal(
    isOpened && (
      <Form
        onSubmit={archive}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change to Inactive?')}
              className={classes.dialog}
              portal={false}
              onClose={close}
              loading={loading}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: close,
                },
              ]}
            >
              <>
                {extraContent && <div className={classes.content}>{extraContent}</div>}
                <Field
                  name={noteField || ''}
                  component={Input}
                  label={label}
                  validate={validation}
                  required
                  maxLength={500}
                  inputType="area"
                />
              </>
            </Modal>
          </form>
        )}
      />
    ),
    document.body
  );

  return { content, actions };
};
